<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div :class="{'is-danger': hasError}">
      <div class="content">
        <ckeditor
          :editor="editor"
          v-model="mValue"
          :config="configuration[configType]"
          @ready="onReady"
          @blur="onBlur"
          @keyup="onChange"
          @focus="onFocus"
          @input="onInput"
        ></ckeditor>
      </div>
      <div v-if="error">
        <span
          v-if="!Array.isArray(error)"
          class="help is-danger"
          v-html="error"
        ></span>
        <div
          v-else
          class="help is-danger"
        >
          <div
            v-for="(message, index) in error"
            :key="`e${index}`"
            v-html="message"
          ></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard'
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    configType: {
      type: String,
      default: 'standard'
    },
    height: {
      type: String,
      default: '90px'
    },
    language: {
      type: String,
      default: 'en'
    },
    error: {
      type: [String, Array],
      default: '',
    },
  },

  components: {
    ckeditor: CKEditor.component
  },

  data() {
    return {
      editor: ClassicEditor,
      mValue: this.value,
      configuration: {
        lite: {
          plugins: [Essentials, RemoveFormat, Bold, Italic, Underline],
          toolbar: ['removeFormat', '|', 'undo', 'redo', '|', 'bold', 'italic', 'underline']
        },
        standard: {
          forcePasteAsPlainText: true,
          plugins: [
            Essentials,
            RemoveFormat,
            Bold,
            Italic,
            Underline,
            Link,
            List
          ],
          toolbar: [
            'removeFormat',
            '|',
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList'
          ],
          ui: {
            height: '300px'
          }
        }
      }
    }
  },

  computed: {
    hasError() {
      if (
        (!Array.isArray(this.error) && this.error) ||
        (Array.isArray(this.error) && this.error.length)
      ) {
        return true
      } else {
        return false
      }
    },
  },

  created() { },

  mounted() { },

  methods: {
    onReady(editor) {
      const clipboardPlugin = editor.plugins.get('Clipboard')
      const editingView = editor.editing.view
      editingView.document.on('clipboardInput', (evt, data) => {
        if (editor.isReadOnly) {
          return
        }

        const dataTransfer = data.dataTransfer

        let content = plainTextToHtml(dataTransfer.getData('text/plain'))

        content = clipboardPlugin._htmlDataProcessor.toView(content)

        clipboardPlugin.fire('inputTransformation', { content, dataTransfer })

        editingView.scrollToTheSelection()

        evt.stop()
      })

      editor.editing.view.document.on(
        'enter',
        (evt, data) => {
          if (data.isSoft) {
            editor.execute('shiftEnter')
          } else {
            editor.execute('enter')
          }

          data.preventDefault()
          evt.stop()
          editor.editing.view.scrollToTheSelection()
        },
        { priority: 'high' }
      )
    },
    onBlur() {
      this.$emit('input', this.mValue)
    },
    onChange() {
      this.$emit('input', this.mValue)
    },
    onFocus() { },
    onInput() {
      this.$emit('input', this.mValue)
    }
  }
}
</script>

<style lang="scss">
.is-danger {
  --ck-color-toolbar-border: red;
  --ck-color-text: red;
  --ck-color-base-border: red;
}
.ck-editor {
  .ck-editor__editable {
    height: 300px;
  }
}
</style>
